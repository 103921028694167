import { Pathnames } from "next-intl/navigation";

export const locales = ["en", "id"] as const;

export const pathnames = {
    "/": "/",
    "/rules": {
        en: "/rules",
        id: "/peraturan",
    },
    "/famous": {
        en: "/famous",
        id: "/terkenal",
    },
    // store.luckynetwork.net
    "/store": {
        en: "/store",
        id: "/toko",
    },
} satisfies Pathnames<typeof locales>;

// Use the default: `always`
// export const localePrefix = undefined;
export const localePrefix = "always" as const;

export type AppPathnames = keyof typeof pathnames;
